/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";

// Navbar
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/black-gradient-background.jpeg";

function SignInBasic() {
  // const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleUsername = (event) => {
    setUsername(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    if (username && password) {
      try {
        const data = { username: username, password: password };
        const response = await fetch(
          "https://j4bxowdj9k.execute-api.us-east-1.amazonaws.com/dev/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const result = await response.json();
        setHasSubmitted(true);
        // TODO: save token to local storage so can retrieve on subdomain
        if (hasSubmitted && !result.error) {
          console.log("Successfully logged in: " + result);
          window.location.replace("https://dashboard.chefaicookware.com");
        }
      } catch (error) {
        console.log("There was an error..." + error);
      }
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <BasicLayout image={bgImage}>
        <Card>
          <MKBox
            variant="gradient"
            bgColor="gold"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MKTypography>
          </MKBox>
          <MKBox pt={4} pb={3} px={3}>
            <MKBox component="form" role="form">
              <MKBox mb={2}>
                <MKInput type="username" label="Username" fullWidth onChange={handleUsername} />
              </MKBox>
              <MKBox mb={2}>
                <MKInput type="password" label="Password" fullWidth onChange={handlePassword} />
              </MKBox>
              {/* <MKBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MKTypography>
              </MKBox> */}
              <MKBox mt={4} mb={1}>
                <MKButton variant="gradient" color="gold" fullWidth onClick={handleLogin}>
                  sign in
                </MKButton>
              </MKBox>
              <MKBox mt={3} mb={1} textAlign="center">
                <MKTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MKTypography
                    component={Link}
                    to="/register"
                    variant="button"
                    color="secondary"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
      </BasicLayout>
    </>
  );
}

export default SignInBasic;
