//

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography variant="h3" mb={3}>
            CHEF AI feature releases and product alerts
          </MKTypography>
          <MKTypography variant="h5" mt={6} mb={3}>
            September 14th 2023 - Feedback System
          </MKTypography>
          <MKTypography variant="body2" color="text">
            We created the first iteration of our smart feedback system. Look out for more details
            in our next YouTube video.
          </MKTypography>
          <br /> <br />
          <MKTypography variant="h5" mt={6} mb={3}>
            September 3rd 2023 - Prototype Updates
          </MKTypography>
          <MKTypography variant="body2" color="text">
            We successfully completed our second prototype with sensors integrated into a real
            airfryer. Additionally, we added the capability to cook fries. We will continue to
            refine the prototype and add more foods to our system.
          </MKTypography>
          <br /> <br />
          <MKTypography variant="h5" mt={6} mb={3}>
            August 18th 2023 - Going public
          </MKTypography>
          <MKTypography variant="body2" color="text">
            We officially deployed our website to the public and will continue to post updates on
            this page.
          </MKTypography>
          <br /> <br />
          <MKTypography variant="h5" mt={6} mb={3}>
            August 15th 2023 - The beginning
          </MKTypography>
          <MKTypography variant="body2" color="text">
            We&apos;re excited to launch our new webpage, our central source of information for all
            our loyal backers on Kickstarter!
          </MKTypography>
          <br /> <br />
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
