// @mui icons
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logo from "assets/images/chefai-transparent.svg";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "CHEF AI",
    image: logo,
    route: "/",
  },
  socials: [
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@CHEF-AI-LLC",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        // { name: "about us", route: "/about-us" },
        { name: "newsletter", route: "/updates" },
        { name: "press", route: "/press" },
      ],
    },
    {
      name: "help & support",
      items: [{ name: "contact us", route: "/contact-us" }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href=""
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        CHEF AI LLC
      </MKTypography>
      .
    </MKTypography>
  ),
};
