// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
// import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";

import shirts from "assets/images/shirts.png";
import MKButton from "components/MKButton";

function Pricing() {
  return (
    <MKBox component="section" py={{ xs: 0, md: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" md={4} mx="auto" textAlign="center">
          <MKTypography variant="h3">Coming Soon!</MKTypography>
          <MKTypography variant="body2" color="text">
            Order a T-Shirt and help be an early supporter of our company
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <MKBox>
            <img src={shirts} width={768} height={384}></img>
          </MKBox>
        </Grid>
        <MKBox display="flex" alignItems="center" justifyContent="center" mt={8} mb={12}>
          <MKButton
            color="gold"
            component="a"
            href="https://forms.gle/vgrsxaTAqdwzwKyZ7"
            target="_blank"
          >
            Pre-Order Now
          </MKButton>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Pricing;
