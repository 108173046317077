// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Newsletter from "pages/presentation/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/black-gradient-background.jpeg";
import logo from "assets/images/chefai-transparent.svg";

function Presentation() {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        minHeight="90vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" alignItems="center" mx="auto">
            <MKBox>
              <img src={logo} height={350} width={350}></img>
            </MKBox>
          </Grid>
          <Grid container item xs={12} lg={7} justifyContent="center" alignItems="center" mx="auto">
            <MKTypography
              variant="h1"
              color="gold"
              mt={-2}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              CHEF AI
            </MKTypography>
          </Grid>
          <MKTypography variant="body1" color="gold" textAlign="center" px={6} mt={1}>
            One touch cooking.
          </MKTypography>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        {/* <MKBox display="flex" alignItems="center" justifyContent="center" mt={8} mb={12}>
          <iframe
            width="40%"
            height="300"
            src="https://www.youtube.com/embed/Vd8Q5-FcTrU"
            title="CHEF AI Demo Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </MKBox> */}
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
