//

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
// import prototype from "assets/images/sensor-showcase.jpg";
// import prototype from "assets/images/render-2.png";

function Banner() {
  return (
    <MKBox
      variant="gradient"
      bgColor="gold"
      position="relative"
      borderRadius="xl"
      mx={{ xs: 2, xl: 3, xxl: 16 }}
      mt={-32}
      py={13}
      px={3}
      sx={{ overflow: "hidden" }}
    >
      <MKBox
        component="img"
        src={bgPattern}
        alt="pattern-lines"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.6}
      />
      <Container sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={7} lg={5} py={{ xs: 0, sm: 6 }} mr="auto" position="relative">
            <MKTypography variant="h2" color="white" mb={1}>
              Pre-order our prototype
            </MKTypography>
            <MKTypography variant="body1" color="white" mb={6}>
              We are sending out a few prototypes to our loyal backers for beta testing to help give
              us feedback and improve the product
            </MKTypography>
            <MKButton
              variant="gradient"
              color="dark"
              component="a"
              href="https://forms.gle/yyAiCKMTmTnLLpe56"
              target="_blank"
            >
              Pre-Order Now
            </MKButton>
            <MKButton variant="text" color="white" sx={{ ml: 1 }}>
              Read more
            </MKButton>
          </Grid>
          {/* <Grid item xs={12} position="absolute" left="50%" mr={-32} width="50%">
            <MKBox
              component="img"
              src={prototype}
              alt="chefai-device"
              width="100%"
              display={{ xs: "none", md: "block" }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Banner;
